$(document).ready(function () {
    if ($("#registerData").length > 0) {
        // Filter op register
        var huidigRegisterPagina = $('.intro h4').text();
        var huidigRegister = huidigRegisterPagina.substr(huidigRegisterPagina.indexOf('Register ') + 9);
        filterAbonnementSoort(huidigRegister);

        $("#registerData").show();
        $('.registers .fa').hide();
    }

    // remove fields if password is succesfully changed
    if($('#Block_ForgotPassword_pnl_changepassword').length > 0){
        if($('#lbl_successmessage').length > 0){
            $('section[id*="Block_CCIPlaceHolder_Block_ForgotPassword"]').html('<h4>Het wachtwoord is succesvol gewijzigd, klik <a href="/my-profile">hier</a> om naar jouw profiel te gaan</h4>');
        }
    }

    //manipulate bedrijfsaanpasser attribute list.
    if(document.querySelector('.bedrijfsaanpasserWijzigAttributen')){
        $(".attribute_container table tr td label:contains('Attribute_title')").each(function() {
            var titlePrefix = $(this).parent().parent().next().find('label').text();
            var category = titlePrefix.split('-')[0];
            $(".attribute_container table tr td label").each(function() {
                var title = $(this).text();
                if (title.indexOf(category) !== -1) {
                    $(this).parent().parent().addClass('attribute_' + category);
                    $(this).parent().parent().hide();
                    
                }
            });
            $(this).text(category)
            $(this).prev().hide();
            $(this).addClass('catagory-title');
            $(this).on( "click", function() {
                $(".attribute_" + category).toggle()
                $(this).toggleClass('active');
            });
        });
    }

});

$(document).ajaxStop(function () {
    var huidigeLabel = $('#lbl_usernameretrievepassword').text();

    // remove link to workgroup members due to STS
    if($('#workgroupMembersList').length > 0){
        $("#workgroupMembersList a").each(function(){
            $(this).contents().unwrap();
        });
    }

    var emailFieldFunctionAdd = $('.CrmSiteProfileMutationsAddFunctionRenderControl_email span').text();
    if(emailFieldFunctionAdd === 'E-mailadres')
    {
        $('.CrmSiteProfileMutationsAddFunctionRenderControl_email span').text('E-mailadres algemeen');
    }

    var emailFieldFuntionEdit = $('.CrmSiteProfileMutationsEditFunctionRenderControl_email span').text();
    if(emailFieldFuntionEdit === 'E-mailadres')
    {
        $('.CrmSiteProfileMutationsEditFunctionRenderControl_email span').text('E-mailadres algemeen');
    }

    // Indien label gelijk is dan veranderen
    if (huidigeLabel === 'Vul uw gebruikersnaam of emailadres in. Je ontvangt login informatie per email.') {
        $('#lbl_usernameretrievepassword').text('Bent u uw wachtwoord vergeten of wilt u een nieuw wachtwoord aanvragen? Vul hieronder uw e-mailadres of oude gebruikersnaam in. U ontvangt van ons een e-mail met instructies voor het aanmaken van een (nieuw) wachtwoord.');
    }
    {
        var huidigeLabel = $('#lbl_unable_to_send_password_msg').text();

        // Indien label gelijk is dan veranderen
        if (huidigeLabel === 'De door u verstrekte gegevens zijn niet bekend in ons systeem. Neem contact met ons op zodat wij u kunnen helpen.') {
            $('#lbl_unable_to_send_password_msg').text('Deze gegevens zijn niet bekend in ons systeem. Neem contact met ons op zodat wij u kunnen verder helpen');
        }
    }

    if($('.edit_person_container').length > 0 ){
        $('.CrmSiteProfileMutationsEditPerson_initialen span').text('Voorletters');
        $('.CrmSiteProfileMutationsEditPerson_tussenvoegsel span').text('Voorvoegsel');
        $('.CrmSiteProfileMutationsEditPerson_geboortedatum span').text('Geboren op');
        $('.CrmSiteProfileMutationsEditPerson_postadres span').text('Adres');
        $('.CrmSiteProfileMutationsEditPerson_postplaats span').text('Plaatsnaam');
    }
});

function filterAbonnementSoort(gekozenAbonnementSoort) {
    $('.registers tr:not(:first-child)').each(function() {
        var huidigAbonnementSoort = $(this).attr('data-abonnement');

        if (huidigAbonnementSoort.toLowerCase() != gekozenAbonnementSoort.toLowerCase()) {
            $(this).remove();
        }
    });
}

// Filter op achternaam (user input)
/*function filterOpAchternaamFunctie() {
    // Declare variables
    var input, filter, table, tr, td, i;
    input = document.getElementById("filterOpAchternaamID");
    filter = input.value.toUpperCase();
    table = document.getElementById("registerTableID");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
            if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }
    // clear field filterOpPlaatsnaamID when text exists in field filterOpAchternaamID
    $("#filterOpPlaatsnaamID").val('');
}*/

function filterOpAchternaamFunctie() {
    // Declare variables
    var input = document.getElementById("filterOpAchternaamID");
    var filter = input.value.toUpperCase();

    // Loop through all table rows, and hide those who don't match the search query
    $('#registerTableID tr:not(:first-child)').each(function() {
        $(this).find('td:last-child').each(function() {
            var tekst = $(this).text();
            if (tekst != '') {
                if (tekst.toUpperCase().indexOf(filter) > -1) {
                    $(this).parent().show();
                } else {
                    $(this).parent().hide();
                }
            }
        });
    });

    // clear field filterOpPlaatsnaamID when text exists in field filterOpAchternaamID
    //$("#filterOpPlaatsnaamID").val('');
}

// Write on keyup event of keyword input element
/*
$("#filterOpAchternaamID").keyup(function(){
    var searchText = $(this).val().toLowerCase();
    // Show only matching TR, hide rest of them
    $.each($("#registerTableID tbody tr"), function() {
        if($(this).text().toLowerCase().indexOf(searchText) === -1)
            $(this).hide();
        else
            $(this).show();
    });
});
*/

